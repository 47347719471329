/**
 * Project AGV Card
 *
 * @author Anja Bisako <anja.bisako@thingos.io>
 * @version 0.0.1
 */
import { HStack } from '@domir/react-stacks';
import Img, { FixedObject } from 'gatsby-image';
import { transparentize } from 'polished';
import * as React from 'react';
import styled from 'styled-components';

import { colors } from '../styles/variables';
import { Body } from './Typography';

const ProjectAGVCardStyled = styled.div({
	padding: `16px`,
	margin: `16px`,
	backgroundColor: colors.white,
	color: `${transparentize(0.5, colors.black)}`,
	maxWidth: 430,
});

const ProjectAGVBody = styled(Body)({});

interface SolutionCardProps {
	image?: FixedObject | FixedObject[];
}

const ProjectAGVCard: React.FC<SolutionCardProps> = props => {
	const { image, children } = props;

	return (
		<ProjectAGVCardStyled>
			{image && (
				<HStack spacing={16}>
					<Img fixed={image} />
				</HStack>
			)}
			<HStack spacing={16}>
				<ProjectAGVBody>{children}</ProjectAGVBody>
			</HStack>
		</ProjectAGVCardStyled>
	);
};

export default ProjectAGVCard;
