import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';

import Container from '../components/Container';
import {Page} from '../components/Page';
import ProjectAGVCard from '../components/ProjectAGVCard';
import { HeaderH2 } from '../components/Typography';
import {IndexLayout} from '../layouts';

const IndexPage = () => {
	const images = useStaticQuery(query);

	return (
		<IndexLayout>
			<Page>
				<Container>
					<HeaderH2>BoxAGV</HeaderH2>

					<HeaderH2>Low-Cost AGV conforming to the VDA 4500 form-factor</HeaderH2>

					<ProjectAGVCard image={images.AGVBox.childImageSharp.fixed}>
						The BoxAGV is an industry{' '}
						<a href="https://en.wikipedia.org/wiki/Automated_guided_vehicle">AGV</a> conforming to
						the <a href="https://en.wikipedia.org/wiki/Euro_container">VDA 4500</a> form factor at a
						low-cost price point.
					</ProjectAGVCard>

					<ProjectAGVCard image={images.AGVLineFollowing.childImageSharp.fixed}>
						Flexible camera guidance system towards the floor makes it compatible with both Bosch
						Rexroth Intelligent Floor and conventional line following. No RF-connection or setup is
						needed. A line is detected and the AGV goes to work.
					</ProjectAGVCard>

					<ProjectAGVCard>
						*MISSING VIDEO*
						<br /> The target price point is below 1.500 €. With a form factor conforming to the VDA
						4500 standard in the variant C-KLT 6421 – 532 x 346 x 166,8mm they are sure to fit into
						most industrial settings. They fit other VDA conform boxes on top as well as being a box
						themselves, fitting perfectly into established logistics with EUR-pallets – ideal for
						brown field integration.
					</ProjectAGVCard>

					<ProjectAGVCard image={images.AGVWheels.childImageSharp.fixed}>
						Omnidirectional movement with mecanum wheels guarantees effective transport even in the
						most space-constrained environments. Top speed is up to 1 m/s and load capacity up to
						50kg. The BoxAGV solution space spans both office and industry sectors.
					</ProjectAGVCard>

					<ProjectAGVCard image={images.AGVPackage.childImageSharp.fixed}>
						Status color communication makes the interaction with the AGV simple and intuitive.
						ThingOS integration makes communication with all parts of your IoT infrastructure
						possible.
					</ProjectAGVCard>

					<ProjectAGVCard image={images.AGVCollision.childImageSharp.fixed}>
						Ultrasonic sensors provide collision avoidance while low speeds and low load guarantee
						safety.
					</ProjectAGVCard>

					<ProjectAGVCard image={images.AGVBeer.childImageSharp.fixed}>
						Friday 16:00. Perfect not only for the transport of industrial goods. 😉
					</ProjectAGVCard>
				</Container>
			</Page>
		</IndexLayout>
	);
};

export const query = graphql`
	query {
		AGVBox: file(relativePath: { eq: "agv-box.jpg" }) {
			childImageSharp {
				fixed(width: 400, height: 300) {
					...GatsbyImageSharpFixed
				}
			}
		}
		AGVLineFollowing: file(relativePath: { eq: "agv-line-following.jpg" }) {
			childImageSharp {
				fixed(width: 400, height: 300) {
					...GatsbyImageSharpFixed
				}
			}
		}
		AGVVideo: file(relativePath: { eq: "AGVVideo.mpg" }) {
			childImageSharp {
				fixed(width: 400, height: 300) {
					...GatsbyImageSharpFixed
				}
			}
		}
		AGVWheels: file(relativePath: { eq: "agv-wheels.jpg" }) {
			childImageSharp {
				fixed(width: 400, height: 300) {
					...GatsbyImageSharpFixed
				}
			}
		}
		AGVPackage: file(relativePath: { eq: "agv-package.jpg" }) {
			childImageSharp {
				fixed(width: 400, height: 300) {
					...GatsbyImageSharpFixed
				}
			}
		}
		AGVCollision: file(relativePath: { eq: "agv-collision.jpg" }) {
			childImageSharp {
				fixed(width: 400, height: 300) {
					...GatsbyImageSharpFixed
				}
			}
		}
		AGVBeer: file(relativePath: { eq: "agv-beer.jpg" }) {
			childImageSharp {
				fixed(width: 400, height: 300) {
					...GatsbyImageSharpFixed
				}
			}
		}
	}
`;

export default IndexPage;
